/* Global styles */

  
  .info-page {
    padding: 0px 20px; /* Add some padding for visual spacing */
    max-width: 350px; /* Set a reasonable maximum width for responsiveness */
    min-width: 300px; /* Set a reasonable maximum width for responsiveness */
    margin: 0 auto; /* Center the content horizontally */
    padding: 25px 15px;
    border-radius: 15px;
    background-color: white;
  }

  @media (max-width : 650px) {
    .info-page{
      width: 95%;
    margin: 5px 10px;
  
    }
  }
  
  .page-title {
    color: #4b566b; /* Darker shade for better contrast */
    margin-bottom: 20px;
  }
  
  /* Slide container styles */
  .slide-container {
    position: relative; /* Needed for swipe implementation */
    overflow: hidden; /* Hide overflowing content */
    min-height: 400px;
  }
  
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute; /* Enable swiping */
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out; /* Smooth slide transitions */
  }
  
  .slide.active {
    transform: translateX(0); /* Position the active slide */
  }
  
  .slide:not(.active) {
    transform: translateX(-102%); /* Hide inactive slides to the left */
  }
  
  /* Slide content styles */
  .slide-title {
    font-size: 22px;
    font-weight: bold;
    color: #4b566b;
    margin-bottom: 15px;
    text-align: center;
  }

  .slide-list{
    margin: 3px 15px;
    font-size: 0.9rem;
  }
  
  .slide-image {
    width:70%;
    max-width: 400px;
    border-radius: 6px; /* Add some rounded corners */
    margin-bottom: 15px;
    text-align: center;
  }
  
  .slide-text {
    font-size: 0.85rem;
    color: #737b8c; /* Use a muted shade for auxiliary text */
  }
  
  /* Navigation buttons */
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .swiper-button-prev,
  .swiper-button-next {
    background-color: #4b566b; /* Match main color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: #0062cc; /* Darken on hover */
  }
  
  /* Video styles (optional) */
  .slide-video {
    width: 100%;
    max-width: 600px;
  }
  
  .slide-video-poster {
    width: 100%;
    max-width: 600px;
  }

  .inactive{
    background-color: lightgray;
  }
  .inactive:hover{
    background-color: lightgray;
  }
  