/* Basic styling */




.dashboard {
  width: 300px;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin: 15px 10px;
}

.profilPicture{
  border-radius: 100px;
  width: 30px;
  height: 30px;
}

@media (max-width : 650px) {
  .dashboard{
    width: 95%;
  margin: 5px 10px;

  }
}

.head {
  padding: 5px;
  color: white;
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 20%;
  align-items: center;
}

.tools {
  width: 30%;
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}

.tools img {
  margin: 0px 0px 0px 10px;
}

.title {
  width: 40%;
  color: #4b566b;
  text-align: center;
  font-weight: bold;
}

.card {
  background-color: #e6f2ff;
  border-radius: 10px;
  padding: 10px;
}

.button{
  margin: 15px 15px 10px 15px;
  padding: 15px;
  border-radius: 15px;
  background-color: #4b566b;
  width: auto;
  color: white;
  align-items: center;
}

.btn-green{
  background-color: green;
  display: flex;
}

.buttonContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.m-0
{
 margin: 2px!important;  
}

.buttonContainer-2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.sapceTextContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.linedBox{
  border : solid 1px rgba(0,0,100,0.1);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px 0px;

}