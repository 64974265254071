/* Basic styling */




.statsboard {
  width: 1000px;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin: 15px 10px;
}
.display-2{
display: flex;
justify-content: space-around;
flex-wrap: wrap;
}
.display-2 div.box{
  width: 45%;
  padding: 10px;
}
h2{
  margin-bottom: 10px;
}
.stats-clients{
  padding: 10px;
  border-radius: 10px;
  background-color: #eaeaea;
}
.stats-clients div{
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
}
.stats-clients div span{
  font-weight: bold;
}
.fix-btn{
  padding: 15px 10px;
  border-radius: 5px;
  background-color: rgb(55, 55, 219);
  color: white;
  font-weight: 900;
  position: fixed;
  z-index: 88;
  top: 20;
  left: 20;
  
}