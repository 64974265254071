/* Basic styling */


  .form{
    border-radius: 12px;
    background-color: white;
    padding: 0px 50px;
  }
  
  @media (max-width : 650px) {
    .form{
      width: 95%;
    margin: 5px 10px;
  
    }
  }
  
  /* Additional styling for aesthetics */
 
  p {
    color: #737b8c; /* Use a muted shade for auxiliary text */
  }
  
  
  