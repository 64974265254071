.App-logo {
  height: 10vmin;
  pointer-events: none;
}

body {
  font-family: sans-serif;
  background-color: #b5c9dd;
  padding: 15px;
  /* Set a white background for the form area */

}

#root{
  justify-content: center;
  display: flex;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
}

.display-none {
  display: none;
}

.App {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #b5c9dd;
  /* Set a white background for the form area */
  border-radius: 8px;
  /* Add some rounded corners for a softer look */
  /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 80;
  background-color: rgba(74, 126, 129, 0.247);
  backdrop-filter: blur(2px);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.info-loader {
  width: 100%;
  padding: 15px 20px;
  margin: 20px 30%;
  text-align: center;
  font-weight: bold;
  border-radius: 15px;
  background-color: white;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px 0px;
}

h1 {
  color: #4b566b;
}

.mb {
  margin-bottom: 15px;
}

.mt {
  margin-top: 15px;
}
.mt-2 {
  margin-top: 40px;
}

._mt {
  margin-top: -15px;
  background-color: white;
  padding: 0px 15px;
  width: max-content;
}

.ml {
  margin-left: 15px;
}

.mr {
  margin-right: 15px;
}

.divider {
  border: solid 1px rgba(50, 50, 50, 0.1);
}

.c-orange {
  color: #fe4513;
}

.c-green {
  color: #3cb431;
}

.w100 {
  margin: 20px 5%;
}

.close-btn {
  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 15px;
  color: rgba(182, 27, 27, 0.438);
}

.input-alert,
.input-alert:focus,
.input-alert::selection,
.input-alert:active {
  border-color: red !important;
  color: red !important;
}

button {
  background-color: #007bff;
  color: white;
  border: none; /* Remove default border */
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer; /* Indicate clickable state */
  transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
  margin: 20px 0px 0px 0px;

  }

button:hover:enabled {
  background-color: #0062cc; /* Darken button on hover */
}

.back-btn {
  align-self: flex-start;
  padding: 15px 10px;
  margin: -5px 2px 5px;
  background-color: white;
  width: max-content;
  line-height: 0px;
  border-radius: 50px;
  font-weight: 900;
}

.back-container {
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}