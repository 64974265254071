/* Basic styling */

 

  .form{
    border-radius: 12px;
    background-color: white;
    padding: 10px 20px 50px 20px;
    max-width: 400px;
  }
  
@media (max-width : 650px) {
  .form{
    width: 95%;
  margin: 5px 10px;

  }
}

  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    color: #4b566b; /* A darker shade for better contrast */
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"], select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    background-color: #f5f8fa; /* Lighter shade for input fields */
    color: #4b566b;
    font-weight: bold;
  }
  
  button:disabled  {
   
    background-color: #90989c; /* Lighter shade for input fields */
    
  }
  
  /* Additional styling for aesthetics */
  h3{
    margin: 2px;
    text-align: left;
  }
  
  p {
    color: #737b8c; /* Use a muted shade for auxiliary text */
  }

  .optionContainer{
    width: 90%;
    padding: 0px 10px 10px 10px;
  }

  .option{
    width: 90%;
    display: flex;
    border-radius: 15px;
    padding: 5px;
    margin: 10px 0px;
  }

  .optionSelected{
    background-color: #d1e3f7;
    padding: 5px ;
  }

  .optionImg{
    width:  30%;
    height: 70px;
    border-radius: 10px;
  }

  .optionLabel{
    width: 80%;
    margin: 0px 10px;
  }

  .optionLabel p{
    margin: 0px;
    text-align: left;
    font-size: 0.85rem;
  }

  .optionLabel b{
    color: orangered;
    font-size: 1rem;
  }
  
  .highlightedText{
    color: seagreen;
  }

  .paymentBox{
    border-radius: 20px;
    background-color: #d1e3f7;
    padding: 10px 15px;
  }